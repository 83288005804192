import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import { Grid, RadioGroup, Radio, FormControlLabel, FormControl, Select, MenuItem, Chip, Paper, FormGroup, InputLabel,Divider } from '@material-ui/core'
import SEO from '../../components/seo'
import ProductList from '../../components/productList'
import { makeStyles } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import Cat from '../../components/custom-icons/cat'
import DogIcon from '../../components/custom-icons/dog-icon'
import DryFood from '../../components/custom-icons/dry-food'
import WetFood from '../../components/custom-icons/wet-food'


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(5),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2),
        }
    },
    paper: {
        paddingBottom: theme.spacing(5),
        marginTop: theme.spacing(5),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),

    },
    center: {
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    form: {
        display: 'flex',
        flexDirection: 'column!important'
    },
    checkedIcon: {
        color: theme.palette.secondary.main
    },
    icon: {
        color: theme.palette.text.primary
    },
    divider:{
        marginTop:theme.spacing(2),
        marginBottom:theme.spacing(2)
    }

}))

const ageOptions = [{ label: 'Yavru', value: '0-12 months' }, { label: 'Yetişkin', value: '1-10 years' }, { label: 'Yaşlı', value: '11+' }]


const FilterForm = ({ onFilter }) => {
    const { handleSubmit, control, formState: { errors } } = useForm();
    const onSubmit = data => {
        console.log(data)

    }
    const classes = useStyles()

    console.log(errors);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className={classes.form}>
                <FormControl component="fieldset">

                    <Controller
                        rules={{ required: true }}
                        control={control}
                        defaultValue=''
                        name="type"
                        render={({ name, onBlur, onChange, value }) => (
                            <RadioGroup
                                value={value}
                                row
                                onBlur={onBlur}
                                onChange={(e) => {
                                    e.preventDefault()
                                    onChange(e.target.value);
                                    onFilter({ name: name, value: e.target.value });

                                }}
                                className={classes.center}
                            >
                                <FormControlLabel
                                    value='Kedi'
                                    control={<Radio
                                        icon={<Cat name='cat-icon' width='50px' height='auto' viewBox='0 0 1728 1728' className={classes.icon} />}
                                        checkedIcon={<Cat name='cat-icon' width='50px' height='auto' viewBox='0 0 1728 1728' className={classes.checkedIcon} />}
                                    />}



                                />
                                <FormControlLabel
                                    value='Köpek'
                                    control={<Radio
                                        icon={<DogIcon name='dog-icon' width='50px' height='auto' viewBox='0 0 1728 1728' className={classes.icon} />}
                                        checkedIcon={<DogIcon name='dog-icon' width='50px' height='auto' viewBox='0 0 1728 1728' className={classes.checkedIcon} />}
                                    />}
                                />

                            </RadioGroup>
                        )}
                    />

                </FormControl>
                <Divider className={classes.divider}/>
                <FormControl component="fieldset"  >

                    <Controller
                        rules={{ required: true }}
                        control={control}
                        defaultValue=''
                        name="category"
                        render={({ name, onBlur, onChange, value }) => (
                            <RadioGroup
                                value={value}
                                onBlur={onBlur}
                                onChange={(e) => {
                                    e.preventDefault()
                                    onChange(e.target.value);
                                    onFilter({ name: name, value: e.target.value });

                                }}
                                className={classes.center}
                            >
                                <FormControlLabel
                                    value='Kuru Mama'
                                    control={<Radio
                                        icon={<DryFood name='dry-food' width='40px' height='auto' viewBox='0 0 1728 1728' className={classes.icon} />}
                                        checkedIcon={<DryFood name='dry-food' width='40px' height='auto' viewBox='0 0 1728 1728' className={classes.checkedIcon} />}
                                    />}
                                    label='Kuru Mama'


                                />
                                <FormControlLabel
                                    value='Yaş Mama'
                                    control={<Radio
                                        icon={<WetFood name='wet-food' width='40px' height='auto' viewBox='0 0 1728 1728' className={classes.icon} />}
                                        checkedIcon={<WetFood name='wet-food' width='40px' height='auto' viewBox='0 0 1728 1728' className={classes.checkedIcon} />}
                                    />}
                                    label='Yaş Mama'

                                />

                            </RadioGroup>
                        )}
                    />

                </FormControl>
                <Divider className={classes.divider}/>

                <FormControl component="fieldset">
                    <InputLabel id="demo-simple-select-readonly-label">Yaşını Seçiniz</InputLabel>
                    <Controller

                        rules={{ required: true }}
                        control={control}
                        defaultValue={[]}
                        name="age"
                        render={({ name, onBlur, onChange, value }) => (
                            <Select
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                value={value}
                                onBlur={onBlur}
                                multiple
                                onChange={(e) => {
                                    e.preventDefault()
                                    onChange(e.target.value);
                                    onFilter({ name: name, value: e.target.value });

                                }}
                                renderValue={selected => {
                                    return (

                                        <div>
                                            {(selected).map((value) => (
                                                <Chip key={value} color='secondary' label={ageOptions.find(el => el.value === value).label} />
                                            ))}
                                        </div>
                                    )
                                }}
                            >


                                {ageOptions.map((el, index) =>
                                    <MenuItem value={el.value} key={index}>{el.label}</MenuItem>


                                )}


                            </Select>
                        )}
                    />

                </FormControl>
                <FormControl>

                </FormControl>
            </FormGroup>

        </form>
    );

}

function filterPet(filter) {
    return function (element) {
        if (filter?.type === null) { return true }
        let searchKey = filter.type == 'Kedi' ? true : false
        return element.for_cats === searchKey
    }
}

function filterCategory(filter) {
    return function (element) {
        if (filter?.category === null) { return true }
        return element.type.includes(filter.category)
    }
}
function filterAge(filter) {
    return function (element) {
        if (filter?.age === null) { return true }
        return filter.age.some(el => element.ageGroup.includes(el))
    }
}


const ProductSection = (props) => {
    const products = props.products
    const [filtered, setFiltered] = useState({ type: null, category: 'Kuru Mama', age: ['0-12 months', '1-10 years', '11+ years'] });
    const [filteredProducts, setFilteredProducts] = useState([])
    const classes = useStyles()
    const handleSelection = ({ name, value }) => {
        console.log(`${name} ve budur: ${value}`)
        setFiltered(prev => ({ ...prev, [name]: value }))
    }

    useEffect(() => {

        setFilteredProducts(products)
        return () => console.log('run')
    }, [products])

    useEffect(() => {
        setFilteredProducts(products.filter(filterPet(filtered)).filter(filterCategory(filtered)).filter(filterAge(filtered)))
    }, [filtered])
    console.log(filteredProducts)
    return (
        <Grid container>
            <Grid item xs={12} md={2}>
                <Paper className={classes.paper}>
                    <FilterForm onFilter={handleSelection} />
                </Paper>
            </Grid>
            <Grid item xs={12} md={9} className={classes.root}>

                {filteredProducts.length > 0 &&
                    <ProductList products={filteredProducts} title='Mamalarımız' nodeLocale='tr-TR' />
                }

            </Grid>
        </Grid>
    )
}



const ProductPage = ({ data, location }) => {
    const products = data.allContentfulProduct.nodes

    const classes = useStyles()

    return (
        <Layout nodeLocale='tr-TR' location={location}>
            <SEO lang='tr-TR' title='4 ayaklı dostlarımız için birbirinden lezzetli mamalarımız' location={location} />
            <ProductSection products={products} />
        </Layout>
    )

}




export const query = graphql`
query ProductListQuery {
    allContentfulProduct(filter: {brand: {brandName: {eq: "Prochoice"}, node_locale: {eq: "tr-TR"}}}) {
      nodes {
        ageGroup
        breeds
        contentful_id
        for_cats
        name
        id
        segment
        slug
        teaser
        type
        labels {
            id
            name
            label
          }
        packagePhoto {
            fluid(maxHeight: 360, quality: 100) {
                ...GatsbyContentfulFluid,
                
            }
        }
      }
    }
}
`
export default ProductPage
